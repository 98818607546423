import ButtonMaterial from '@material-ui/core/Button';
import React, { CSSProperties } from 'react';
import { primary, white } from 'src/utils/Colors';

type Props = {
    type: 'round' | 'round_small' | 'sharp_wide' | 'round_big' | 'round_verybig';
    customStyle?: CSSProperties;
    href?: string;
    rel?: string;
    shouldOpenInNewTab?: boolean;
    function?: Function;
    color?: string;
    children: any;
};
interface Style {
    [key: string]: any;
}
const defaultStyles: Style = {
    //This styling is taken from the Registration button, it is by no means the best default styling.
    round: {
        zIndex: 1,
        width: '200px',
        height: '60px',
        marginTop: '20px',
        backgroundColor: `${primary}`,
        fontFamily: 'Roboto-Medium',
        borderRadius: '8px',
        fontSize: '24px',
        textTransform: 'none',
        textDecoration: 'none'
    },
    round_small: {
        zIndex: 1,
        width: '160px',
        height: '50px',
        marginTop: '10px',
        marginBottom: '30px',
        marginRight: '10px',
        backgroundColor: `${primary}`,
        fontFamily: 'Roboto-Medium',
        borderRadius: '8px',
        fontSize: '14px',
        color: `${white}`,
        textTransform: 'none',
        textDecoration: 'none'
    },
    round_big: {
        zIndex: 1,
        width: '180px',
        height: '45px',
        marginTop: '0px',
        marginBottom: '30px',
        marginRight: '30px',
        backgroundColor: `${primary}`,
        fontFamily: 'Roboto-Medium',
        borderRadius: '8px',
        fontSize: '16px',
        color: `${white}`,
        textTransform: 'none',
        textDecoration: 'none'
    },
    round_verybig: {
        zIndex: 1,
        width: '200px',
        height: '60px',
        marginTop: '20px',
        marginBottom: '30px',
        marginRight: '30px',
        backgroundColor: `${primary}`,
        fontFamily: 'Roboto-Medium',
        borderRadius: '8px',
        fontSize: '18px',
        color: `${white}`,
        textTransform: 'none',
        textDecoration: 'none'
    },
    sharp_wide: {
        zIndex: 1,
        width: '200',
        height: '40',
        marginTop: '20',
        backgroundColor: `${primary}`,
        fontFamily: 'Roboto-Medium',
        borderRadius: '8',
        fontSize: '14',
        color: `${white}`,
        textTransform: 'none',
        textDecoration: 'none'
    }
};
const combine_dicts = (
    dict1: Record<string, any>,
    dict2: Record<string, any>
) => {
    //Dict2 overwrites existing values in dict1
    for (var key in dict2) {
        dict1[key] = dict2[key];
    }
    return dict1;
};
const handleClick = (props: Props) => {
    if (props.function) {
        //Leaving this as an if in case you want to call a function and send to a link
        props.function();
    }
};
const Button = (props: Props) => {
    let style: Style = defaultStyles[props.type];
    if (props.color) {
        style.backgroundColor = props.color;
    }
    if (props.customStyle) {
        style = combine_dicts(style, props.customStyle);
    }
    var buttonContent: JSX.Element = (
        <ButtonMaterial
            variant="contained"
            color="primary"
            style={style}
            onClick={() => handleClick(props)}
        >
            {props.children}
        </ButtonMaterial>
    );
    if (props.href) {
        let a = {
            rel: props.rel ? props.rel : '',
            target: props.shouldOpenInNewTab ? '_blank' : '_self',
            href: props.href
        };
        if (props.rel) {
            a.rel = props.rel;
        }
        buttonContent = (
            <a style={{ textDecoration: 'none' }} {...a}>
                {buttonContent}
            </a>
        );
    }
    return <React.Fragment>{buttonContent}</React.Fragment>;
};

export default Button;
