import React, { useState, CSSProperties } from 'react';
import Text from 'src/components/Text';
import { mineshaft, primary, tundora } from 'src/utils/Colors';
import 'bootstrap/dist/css/bootstrap.min.css';
import Button from 'src/components/Button';

const TournamentInfo = () => {
    const [isSchedExpanded, setIsSchedExpanded] = useState(true);
    const [isMapExpanded, setIsMapExpanded] = useState(false);
    const [isTeamsExpanded, setIsTeamsExpanded] = useState(false);
    const [isInfoExpanded, setIsInfoExpanded] = useState(false);

    const isMobileDevice = () => {
        return /Mobi|Android/i.test(navigator.userAgent);
    };

    const handleSchedClick = () => {
        if (isMobileDevice()) {
            window.location.href = '/pdfs/2025_tournament_schedule.pdf';
        } else {
            setIsSchedExpanded(!isSchedExpanded);
            setIsMapExpanded(false);
            setIsTeamsExpanded(false);
            setIsInfoExpanded(false);
        }
    };

    const handleEventInfoClick = () => {
        if (isMobileDevice()) {
            window.location.href = '/pdfs/2025_event_specs.pdf';
        } else {
            setIsInfoExpanded(!isInfoExpanded);
            setIsSchedExpanded(false);
            setIsMapExpanded(false);
            setIsTeamsExpanded(false);
        }
    };

    const handleMapClick = () => {
        if (isMobileDevice()) {
            window.location.href = '/pdfs/2025_campus_map.pdf';
        } else {
            setIsMapExpanded(!isMapExpanded);
            setIsInfoExpanded(false);
            setIsSchedExpanded(false);
            setIsTeamsExpanded(false);
        }
    };

    const handleTeamsClick = () => {
        if (isMobileDevice()) {
            window.location.href = '/pdfs/2025_team_list.pdf';
        } else {
            setIsTeamsExpanded(!isTeamsExpanded);
            setIsInfoExpanded(false);
            setIsSchedExpanded(false);
            setIsMapExpanded(false);
        }
    };

    return (
        <div style={styles.section}>
            <Text large black color={mineshaft} customStyle={{ margin: 0 }}>
                tournament day.
            </Text>
            <hr style={styles.titleLine} />
            <p style={{ lineHeight: 1.5, marginTop: 0 }}></p>
            <Text
                customStyle={{ lineHeight: 1.5, marginTop: 0 }}
                color={tundora}
            >
                <i>Last updated: January 25, 2025</i>
            </Text>
            <Button
                type="round_small"
                rel="noopener noreferrer"
                function={handleSchedClick}
            >
                Event Schedule
            </Button>

            <Button
                type="round_small"
                rel="noopener noreferrer"
                function={handleEventInfoClick}
            >
                Event Info
            </Button>

            <Button
                type="round_small"
                rel="noopener noreferrer"
                function={handleMapClick}
            >
                Campus Map
            </Button>
            <Button
                type="round_small"
                rel="noopener noreferrer"
                function={handleTeamsClick}
            >
                Team List
            </Button>
            {isSchedExpanded && !isInfoExpanded && !isMapExpanded && !isTeamsExpanded && (
                <iframe
                    title="2025_tournament_schedule"
                    src="/pdfs/2025_tournament_schedule.pdf"
                    style={styles.iframeStyle}
                />
            )}
            {isInfoExpanded && !isSchedExpanded && !isMapExpanded && !isTeamsExpanded && (
                <iframe
                    title="2025_event_info"
                    src="/pdfs/2025_event_info.pdf"
                    style={styles.iframeStyle}
                />
            )}
            {isMapExpanded && !isInfoExpanded && !isSchedExpanded && !isTeamsExpanded && (
                <iframe
                    title="2025_campus_map"
                    src="/pdfs/2025_campus_map.pdf"
                    style={styles.iframeStyle}
                />
            )}
            {isTeamsExpanded && !isInfoExpanded && !isMapExpanded && !isSchedExpanded && (
                <iframe
                    title="2025_team_list"
                    src="/pdfs/2025_team_list.pdf"
                    style={styles.iframeStyle}
                />
            )}
            <Text
                black
                color={mineshaft}
                customStyle={{
                    lineHeight: 1.5,
                    marginTop: 30,
                    fontSize: '25px'
                }}
            >
                Buses
            </Text>
            <Text
                customStyle={{ lineHeight: 1.5, marginTop: 0 }}
                color={tundora}
            >
                You will not be able to park your coach/school bus on campus
                outside of the buildings where team rooms are. You are welcome
                to find your own locations to park your buses, but some places
                we have recommended in the past include:
                <br></br>
                <br></br>
                <li>The Shops at Yale</li>
                <li>Parking at 161 York Street Audubon Court</li>
                <li>Garage Prospect-Sachem Garage</li>
                <br></br>
                <br></br>
                Note that while there is no formal restriction on the types of
                vehicles that can park at these locations, they are set up
                primarily for passenger vehicles. Coaches and teams should keep
                this in mind and work with their respective transportation
                agencies to create a parking plan.
            </Text>
            <Text
                black
                color={mineshaft}
                customStyle={{
                    lineHeight: 1.5,
                    marginTop: 30,
                    fontSize: '25px'
                }}
            >
                Headquarters & Registration
            </Text>
            <Text
                customStyle={{ lineHeight: 1.5, marginTop: 0 }}
                color={tundora}
            >
                The YUSO HQ will be located in William L. Harkness Hall (WLH)
                Room 112, and will be staffed with members of our leadership
                team at all times of the day. We will open for registration at{' '}
                <b>7 a.m.</b> sharp and will continue checking in teams until
                the beginning of the first block! Please check in with us here
                upon arrival to campus—we have a check-in folder for you that
                includes printouts, wristbands for your teams, and other
                important materials, and we need to verify that your students
                are on campus. You should do this before or simultaneously with
                impound. Should you have any questions or concerns (that you are
                unable to communicate with us via Remind), you are welcome to
                come to this room and speak with one of us.
            </Text>
            <Text
                black
                color={mineshaft}
                customStyle={{
                    lineHeight: 1.5,
                    marginTop: 30,
                    fontSize: '25px'
                }}
            >
                Lunch
            </Text>
            <Text
                customStyle={{ lineHeight: 1.5, marginTop: 0 }}
                color={tundora}
            >
                We unfortunately will not be providing food for participants on
                the day of the competition. However, you are more than welcome
                to order food to eat in your assigned home rooms throughout the
                day.
                <br></br>
                <br></br>
                <li>
                    There are a variety of <b>familiar fast food restaurants</b>{' '}
                    in the area that include Panera Bread, Shake Shack, and
                    Chipotle Mexican Grill.
                </li>
                <li>
                    If you're looking for a <b>quick bite</b> but want something
                    more unique to New Haven, places like Junzi, Haven Hot
                    Chicken, Bulldog Hotdogs, and Garden Catering are great
                    options.
                </li>
                <li>
                    There are also a variety of <b>coffee shops and cafes</b> in
                    the area! Some local favorites include Atticus, G Cafe,
                    Booktrader Cafe, Willoughby's, Common Grounds, and
                    Starbucks.
                </li>
                <li>
                    Finally, if you're really just here to get a taste of{' '}
                    <b>New Haven pizza</b>, you should order from the famous
                    Frank Pepe Pizzeria Napoletana or Sally's Apizza. Other
                    great runner-ups include Brick Oven Pizza, Yorkside Pizza,
                    Est Est Est Pizza, and Modern Apizza.{' '}
                </li>
            </Text>
        </div>
    );
};

const styles = {
    section: {
        width: '100%',
        display: 'inline-block',
        textAlign: 'left',
        marginBottom: 60
    } as CSSProperties,
    titleLine: {
        border: 0,
        borderTop: `3px solid ${primary}`,
        display: 'inline-block',
        width: 80,
        color: primary
    } as CSSProperties,
    iframeStyle: {
        width: '100%',
        height: '700px',
        overflow: 'scroll',
        '-webkit-overflow-scrolling': 'touch'
    } as CSSProperties
};

export default TournamentInfo;
