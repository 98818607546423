import PhucPhoto from 'src/assets/people/phuc.jpg';
import JingPhoto from 'src/assets/people/jing.jpg';
import EmilyPoagPhoto from 'src/assets/people/emilypoag.jpg';
import SheelPhoto from 'src/assets/people/sheel.jpg';
import AnhThaiPhoto from 'src/assets/people/anhthai.jpg';
import AidanPhoto from 'src/assets/people/aidan.jpg';
import LeoPhoto from 'src/assets/people/leo.jpg';
import AnthonyPhoto from 'src/assets/people/anthony.jpg';
import TimPhoto from 'src/assets/people/tim.jpg';
import IrenePhoto from 'src/assets/people/irene.jpg';
import GavinPhoto from 'src/assets/people/gavin.jpg';
import XinZhiPhoto from 'src/assets/people/xinzhi.jpg';
import SharnaPhoto from 'src/assets/people/sharna.jpg';
import DavidPhoto from 'src/assets/people/david.jpg';
import JeffreyPhoto from 'src/assets/people/jeffrey.jpg';
import KJPhoto from 'src/assets/people/kj.jpg'
import HarveyPhoto from 'src/assets/people/harvey.jpg';
import LisaPhoto from 'src/assets/people/lisa.jpg';
import SarahPhoto from 'src/assets/people/sarahl.jpg';
import OliviaPhoto from 'src/assets/people/oliviat.jpg';
import JacobPhoto from 'src/assets/people/jacobf.jpg';
import EricaPhoto from 'src/assets/people/ericay.jpg';
import AnniePhoto from 'src/assets/people/anniey.jpg';
import XanderPhoto from 'src/assets/people/xanderg.jpg';
import YuvanPhoto from 'src/assets/people/yuvanc.jpg';
import PeterPhoto from 'src/assets/people/peterl.jpg';
import EmeraldPhoto from 'src/assets/people/emeraldy.jpg';
import HenryPhoto from 'src/assets/people/henryb.jpg';

export interface ProfileInfo {
    photo: string;
    name: string;
    title: string;
    bio: string;
}

export interface TeamInfo {
    teamName: string;
    people: ProfileInfo[];
}

export const FULL_ROSTER: TeamInfo[] = [
    {
        teamName: 'Tournament Directors',
        people: [
            {
                photo: LeoPhoto,
                name: 'Leo Shen',
                title: 'Director',
                bio:
                    'Leo is a sophomore from Auburn, Alabama majoring in Biomedical Engineering. He competed in Science Olympiad for five years in middle and high school, focusing on Ornithology, Fossils, and Designer Genes. In his free time, Leo enjoys visiting new boba shops, dancing with Yale Movement, and working out at Payne Whitney Gym.'
            },
            {
                photo: OliviaPhoto,
                name: 'Olivia Tung',
                title: 'Subdirector',
                bio:
                    'Olivia is a first-year from New York City who is planning on majoring in Neuroscience. She competed in Science Olympiad for three years for Hunter College High School, mainly in Anatomy & Physiology and Chemistry Lab. In her free time, she enjoys arts and crafts, volleyball, and spending time with friends.'
            }
        ]
    },
    {
        teamName: 'Events Directors',
        people: [
            {
                photo: IrenePhoto,
                name: 'Irene Zheng',
                title: 'Director of Events',
                bio:
                    "Irene is a first-year from Bloomington, Minnesota planning to major in applied mathematics. During her time with Jefferson High School's Science Olympiad Team, she enjoyed competing in forensics, code-busters, and ornithology. Outside of YUSO, Irene can be found dancing, listening to music on hammocks, and going on boba runs!"
            },
            {
                photo: TimPhoto,
                name: 'Tim Li',
                title: 'Director of Events',
                bio:
                    'Tim is a sophomore from Madison, Wisconsin planning to major in Applied Mathematics and Computer Science. He competed in Science Olympiad for seven years and was a captain for West High School his senior year. His favorite event was Experimental Design and Fermi Questions (╯°□°)╯︵ ┻━┻. In his free time, he likes to play basketball, watch movies, and hang out with friends.'
            },
            {
                photo: JacobPhoto,
                name: 'Jacob Feldman',
                title: 'Co-Director of Events',
                bio:
                    "Jacob is a sophomore from Mount Kisco, New York majoring in Chemistry and Comparative Literature. He competed on Fox Lane High School's Science Olympiad team for four years and served as Co-Captain during his senior year. In high school, his favorite Science Olympiad events included Chemistry Lab, Forensics, Green Generation, Environmental Chemistry, and—lost but not forgotten—Water Quality. Outside of science, Jacob loves learning foreign languages, American history, and talking about The Simpsons."
            },
            {
                photo: XanderPhoto,
                name: 'Xander Garcia',
                title: 'Co-Director of Events',
                bio:
                    "Xander is a first-year from Fresno, California and is majoring in Mechanical Engineering. From his SciOly elementary school days to being a captain on Edison High's team, he has enjoyed flight, codebusters, and wind power. When Xander isn't locking in, you’ll likely find him in the Murray buttery, playing piano, or testing his “luck” (and perhaps his budgeting skills) at poker."
            },
            {
                photo: AnniePhoto,
                name: 'Annie Ye',
                title: 'Co-Director of Events',
                bio:
                    "Annie is a first-year from Mason, OH majoring in Statistics & Data Science. She competed in Science Olympiad with Mason High School, participating primarily in Chemistry Lab, Environmental Chemistry, and Experimental Design. In her free time, Annie enjoys photography, listening to music, and trying new restaurants with friends."
            },
        ]
    },
    {
        teamName: 'Logistics',
        people: [
            {
                photo: GavinPhoto,
                name: 'Gavin Sun',
                title: 'Director of Logistics',
                bio:
                    'Gavin is a sophomore from St. Paul, Minnesota majoring in Econ and S&DS. He competed in Science Olympiad throughout all four years at his high school, and his favorite events include Green Generation, It\'s About Time, and Machines. In his free time, he enjoys exploring the local food scene, stargazing, and keeping up with current events.'
            },
            {
                photo: EricaPhoto,
                name: 'Erica Yu',
                title: 'Co-Director of Logistics',
                bio:
                    "Erica is an aspiring Ecology and Evolutionary biology major from Brooklyn, New York. She competed on Staten Island Technical High School's team all four years. Her favorite events are ornithology, rocks and minerals, forestry, and fossils. Asides from ID events, she loves to go on hikes, birdwatch, try local food spots, and play board games (Wingspan anyone?)."
            },
            {
                photo: YuvanPhoto,
                name: 'Yuvan Chali',
                title: 'Co-Director of Logistics',
                bio:
                    "Yuvan is a sophomore from St. Louis, Missouri majoring in Chemistry and Computer Science. He has competed in Science Olympiad throughout middle and high school at Ladue Middle School and Ladue High School. His favorite event was Chemistry Lab. In his free time, he enjoys following Liverpool FC and St. Louis CITY SC and spending time with friends."
            }
        ]
    },
    {
        teamName: 'Human Resources',
        people: [
            {
                photo: AnthonyPhoto,
                name: 'Anthony Vazquez',
                title: 'Human Resources',
                bio:
                    'Anthony is a sophomore from Long Island, NY. He participated in Science Olympiad at Chaminade High School, with his favorite events being Dynamic Planet and Rocks and Minerals. He is planning on majoring in Molecular Biophysics and Biochemistry along with a certificate in Spanish. In his free time, he loves to play basketball and practice the violin.'
            }
        ]
    },
    {
        teamName: 'Finance',
        people: [
            {
                photo: XinZhiPhoto,
                name: 'Xinzhi Qiu',
                title: 'Director of Finance',
                bio:
                    'Xinzhi is a sophomore from Singapore and is double majoring in Biomedical Engineering and Economics. He has never competed in Science Olympiad, but has found it very fulfilling in college. In his free time, he enjoys cheering on Chelsea Football Club, and catching the latest flicks on the big screen.'
            },
            {
                photo: HenryPhoto,
                name: 'Henry Burton',
                title: 'Finance',
                bio:
                    'Henry is a freshman from New York City majoring in Economics and Mathematics. He competed in Science Olympiad for two years at Hunter College High School, and his favorite events were Fermi Questions and Optics. Outside of YUSO, he enjoys playing chess, writing math contests, and cooking.'
            }
        ]
    },
    {
        teamName: 'Outreach',
        people: [
            {
                photo: SharnaPhoto,
                name: 'Sharna Saha',
                title: 'Director of Outreach',
                bio:
                    'Sharna is a sophomore from New York who is planning to major in neuroscience, and get a certificate in Global Health with hopes of medical school. She loves reading cool scientific articles about the latest discovery (and anything else she can get her hands on), hanging out with her friends, and baking in her spare time! She can’t wait to get more involved in Science Olympiad!'
            },
            {
                photo: SarahPhoto,
                name: 'Sarah Li',
                title: 'Outreach',
                bio:
                    'Sarah is a sophomore from Baton Rouge, Louisiana, and is majoring in Molecular Biophysics & Biochemistry. She competed in Science Olympiad for seven years with Baton Rouge Magnet High School and Glasgow Middle School. Her favorite events are Anatomy & Physiology, Chem Lab, and Fast Facts. She would love to see Fast Facts rotate back in the future and believes it deserves a permanent spot in the event lineup! In her free time, she loves playing the piano and playing video games with friends.'
            }
        ]
    },
    {
        teamName: 'Engineering & Design',
        people: [
            {
                photo: JeffreyPhoto,
                name: 'Jeffery Wei',
                title: 'Director of Engineering',
                bio:
                    'Jeffrey is a sophomore from Acton, Massachusetts majoring in physics and computer science. He competed for Acton Boxborough Regional High School for four years as a member and later captain. His favorite events were Astronomy, Fermi Questions, and Trajectory when the build worked properly. Outside of YUSO, Jeffrey enjoys watching Arsenal, listening to music, and finding good burrito spots.'
            },
            {
                photo: DavidPhoto,
                name: 'David Dong',
                title: 'Director of Engineering',
                bio:
                    'David is a sophomore from Iowa and participated in Science Olympiad for six years. His favorite events were Wifi Lab and Write it Do it. He likes history podcasts, Overleaf, and poker.'
            },
            {
                photo: PeterPhoto,
                name: 'Peter Loranger',
                title: 'Software Engineer',
                bio:
                    'Peter is a first-year from Westport, Connecticut majoring in physics. He participated in Science Olympiad for four years throughout middle and high school. His favorite event was and still is Disease Detectives. In his free time, he enjoys fencing, good coffee, and watching The West Wing.'
            }
        ]
    },
    {
        teamName: 'Graphic Design',
        people: [
            {
                photo: LisaPhoto,
                name: 'Lisa Chou',
                title: 'Director of Graphic Design',
                bio:
                    'Lisa is a Sophomore from Texas who plans to major in Electrical Engineering. She competed in Science Olympiad for six years throughout middle and high school. Outside of YUSO, you can find Lisa drinking boba with friends.'
            },
            {
                photo: EmeraldPhoto,
                name: 'Emerald Yee',
                title: 'Graphic Designer',
                bio:
                    'Emerald is a first-year from Queens, NYC and is majoring in Neuroscience. She competed in Science Olympiad for three years with Townsend Harris High School. Her favorite events are Microbe Mission and Disease Detectives. In her free time, she likes photographing sunsets, taking long walks, listening to throwback hits, and sleeping until noon.'
            }
        ]
    },
    {
        teamName: 'Advisors',
        people: [
            {
                photo: AidanPhoto,
                name: 'Aidan Pulmano',
                title: 'Advisor',
                bio:
                    'Aidan is a junior from Sugar Land, Texas majoring in Chemical Engineering. He competed in Science Olympiad with Clements High School for two years. Outside of YUSO, Aidan likes to research, conduct night-runs with his suitemates, and FaceTime his two younger brothers back home.'
            },
            {
                photo: AnhThaiPhoto,
                name: 'Anh-Thai Le',
                title: 'Advisor',
                bio:
                    'Anh-Thai is a junior from Southern California planning to major in Mathematics and Physics. He competed in Science Olympiad for 3 years with Beckman High School and was build captain his senior year. In his free time, Anh-Thai likes to binge watch YouTube videos and (try not to) play Brawl Stars.'
            },
            {
                photo: EmilyPoagPhoto,
                name: 'Emily Poag',
                title: 'Advisor',
                bio:
                    'Emily is a junior at Yale (Class of 2026) from Ames, IA. She is a chemistry major who also enjoys taking economics classes. In high school her favorite Science Olympiad events were Forensics and Codebusters. In her free time she enjoys reading, going on runs, getting boba, crocheting, and hanging out with her friends.'
            },
            {
                photo: HarveyPhoto,
                name: 'Harvey Lloyd Picar',
                title: 'Advisor',
                bio:
                    'Harvey is from Hawaii and attended Waipahu High School, competing in multiple scioly events such as Codebusters, Machines, Detector Building, and Astronomy. He is currently a senior, majoring in Computer Science. His other hobbies include playing video games and volleyball for fun!'
            },
            {
                photo: JingPhoto,
                name: 'Jing Lin',
                title: 'Advisor',
                bio:
                    'Jing is a senior from Atlanta, Georgia and is majoring in Molecular Biochemistry and Biophysics. She competed in Science Olympiad for three years and served as a team leader and Co-president for two years at Forsyth Central High School. Jing likes to draw and goes on nature walks while listening to music in her free time.'
            },
            {
                photo: KJPhoto,
                name: 'KJ McConnell',
                title: 'Advisor',
                bio:
                    'KJ is a senior from Charlotte, NC majoring in Astrophysics (and maybe Stats & Data Science). She served as the director of YUSO in the 2023 season and captained her high school team at Providence Day School for 2 years! Outside of YUSO, KJ serves as an aide for her residential college and can be found crocheting in all manner of places.'
            },
            {
                photo: PhucPhoto,
                name: 'Phuc Duong',
                title: 'Advisor',
                bio:
                    'Phuc is a senior from Massachusetts majoring in Computer Science. He competed in Science Olympiad for 4 years and served as the treasurer for two years at South High Community School. His favorite Science Olympiad event was Game On. Outside of YUSO, Phuc enjoys binge watching shows and going on road trips with friends.'
            },
            {
                photo: SheelPhoto,
                name: 'Sheel Trivedi',
                title: 'Advisor',
                bio:
                    'Sheel is a junior from Orlando, Florida majoring in Neuroscience. He participated in Science Olympiad for six years throughout middle and high school. His favorite events were Water Quality, Green Generation, and Potions and Poisons. In his free time, he likes to spend time in the Saybrook Squiche, distract his friends while studying in Bass, and try all the local coffee shops in New Haven.'
            }
        ]
    }
];