export interface SupervisorInfo {
    supervisorName: string;
    collegeYear: string;
}

export interface EventInfo {
    eventName: string;
    supervisors: SupervisorInfo[];
}

const supervisorNameList = [
    'Christian Kang',
    'Carlos Abudo',
    'William Cerny',
    'Tim Li',
    'Anthony Lin',
    'Quinn Ennis',
    'Sophie Heitfield',
    'Jacob Feldman',
    'Michelle So',
    'Zakariya Bouzid',
    'Christian Kang',
    'Lily Gao',
    'William Noguera',
    'Cindy Cai',
    'Sean Liu',
    'Daniel Lu',
    'Shriya Parthasarathi',
    'Noah Beckish',
    'George Sun',
    'Siddiq Mohammed',
    'John Wood',
    'Vedanth Reddyvari',
    'John Wood',
    'Timothy Bui',
    'Peter Nelson',
    'Keith Dai',
    'Jerry Xu',
    'Daniel Lu',
    'Shriya Parthasarathi',
    'Erica Yu',
    'Nathaniel Johnson',
    'George Sun',
    'Weijing Chen',
    'Vedanth Reddyvari',
    'Mohamed Musa',
    'Sean Liu',
    'Weijing Chen',
    'Lily Gao'
];

const collegeYearList = [
    'Yale University',
    'Yale University',
    'Yale University',
    'Yale University',
    'Massachusetts Institute of Technology',
    'Yale University',
    'Yale University',
    'Yale University',
    'Yale University',
    'Yale University',
    'Yale University',
    'Yale University',
    'University of Wisconsin-Madison',
    'Yale University',
    'Yale University',
    'Yale University',
    'University of North Carolina at Chapel Hill',
    'Yale University',
    'Yale University',
    'Yale University',
    'Yale University',
    '',
    'Yale University',
    'Yale University',
    'Yale University',
    '',
    'Yale University',
    'Yale University',
    'University of North Carolina at Chapel Hill',
    'Yale University',
    '',
    'Yale University',
    '',
    '',
    'Ohio State University',
    'Yale University',
    '',
    'Yale University'
];

const eventsList = [
    'Air Trajectory',
    'Anatomy and Physiology',
    'Astronomy',
    'Bungee Drop',
    'Chemistry Lab',
    'Codebusters',
    'Disease Detectives',
    'Dynamic Planet',
    'Ecology',
    'Entomology',
    'Experimental Design',
    'Forensics',
    'Fossils',
    'Geologic Mapping',
    'Helicopter',
    'Materials Science',
    'Microbe Mission',
    'Optics',
    'Tower',
    'Wind Power',
    'Write It Do It',
    'Anatomy and Physiology',
    'Astronomy',
    'Chemistry Lab',
    'Codebusters',
    'Disease Detectives',
    'Ecology',
    'Experimental Design',
    'Forensics',
    'Fossils',
    'Geologic Mapping',
    'Helicopter',
    'Materials Science',
    'Microbe Mission',
    'Optics',
    'Tower',
    'Wind Power',
    'Write It Do It'
];

export const EVENTS_SUPERVISORS: EventInfo[] = [];
const included: Map<string, number> = new Map();

for (let i = 0; i < eventsList.length; i++) {
    const e = eventsList[i];
    if (!included.has(e)) {
        included.set(e, i);
        EVENTS_SUPERVISORS[i] = {
            eventName: eventsList[i],
            supervisors: [
                {
                    supervisorName: supervisorNameList[i],
                    collegeYear: collegeYearList[i]
                }
            ]
        };
    } else {
        const index = included.get(e);
        if (index !== undefined) {
            EVENTS_SUPERVISORS[index].supervisors.push({
                supervisorName: supervisorNameList[i],
                collegeYear: collegeYearList[i]
            });
        }
    }
}

EVENTS_SUPERVISORS.sort((a, b) => a.eventName.localeCompare(b.eventName));
