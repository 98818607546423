import React, { CSSProperties } from 'react';
import { EventInfo, SupervisorInfo } from '../Events';
import { useWindowWidth } from '@react-hook/window-size';
import Text from 'src/components/Text';

interface EventProps {
    event: EventInfo;
}

interface SupervisorProps {
    supervisor: SupervisorInfo;
}

const EventProfile = (props: EventProps) => {
    const { event } = props;
    const windowWidth = useWindowWidth();
    const styles = responsiveStyles(windowWidth);

    return (
        <div style={styles.profile}>
            <Text bold fontSize={20} customStyle={{ marginBottom: '-0.5em' }}>
                {event.eventName}
            </Text>
            <hr style={styles.goldLine} />
            <div style={{ marginTop: '0.2em' }}>
                {event.supervisors.map((e, index) => (
                    <SupervisorProfile key={index} supervisor={e} />
                ))}
            </div>
        </div>
    );
};

const SupervisorProfile = (props: SupervisorProps) => {
    const { supervisor } = props;
    return (
        <>
            <Text medium fontSize={16}>
                {supervisor.supervisorName}
                {supervisor.collegeYear ? `, ${supervisor.collegeYear}` : ''}
            </Text>
        </>
    );
};

const responsiveStyles = (windowWidth: number) => ({
    profile: {
        width: '100%',
        display: 'inline-block',
        textAlign: 'left',
        marginBottom: 60
    } as CSSProperties,
    goldLine: {
        border: 0,
        borderTop: '3px solid gold',
        display: 'inline-block',
        width: 160,
        color: 'gold',
        margin: 0
    } as CSSProperties
});

export default EventProfile;
